
import SegmentedBar from "../SegmentedBar/SegmentedBar.vue";

export default {
  components: { SegmentedBar },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Object, Boolean],
      default: () => null,
    },

    label: {
      type: [String, Number, Object],
      required: true,
    },

    colorName: {
      type: String,
      required: true,
    },

    minWidth: {
      type: String,
      default: "0",
    },

    maxWidth: {
      type: String,
      default: "100%",
    },

    valueIsLessThanIndex: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _value: {
      get() {
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      },
    },

    _valueLessThanIndex: {
      get() {
        if (this.valueIsLessThanIndex) {
          return this.value - 1;
        }
        return this.value;
      },

      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
